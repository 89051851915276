import React from "react";

export default function PostShoot({ packageShow, packageDocReady, heading }) {
  return (
    <>
      {packageDocReady && (
        <div className=" my-5">
          <p data-cy="package-postShoot" className={heading}>
            Post-Shoot
          </p>
          <div className="mx-2 md:mx-0 grid md:grid-cols-2">
            <div>
              No. Images:{" "}
              <span data-cy="package-noImages">{packageShow.noImages}</span>
            </div>
            {/* <div>Images: {packageShow.shootImages}</div> */}
            {packageShow.shootImages === "edited_and_unedited" && (
              <div data-cy="package-imagesOutcome">
                Images: Edited and Unedited Images
              </div>
            )}
            {packageShow.shootImages === "unedited_images" && (
              <div data-cy="package-imagesOutcome">Images: Unedited Images</div>
            )}
            {packageShow.shootImages === "edited_images" && (
              <div data-cy="package-imagesOutcome">Images: Edited Images</div>
            )}
            {packageShow.noVideos && (
              <div data-cy="package-noVideos">
                No. Videos: {packageShow.noVideos}
              </div>
            )}
            {packageShow.videoDuration && (
              <div data-cy="package-videoMin">
                Video Duration: {packageShow.videoDuration}min
              </div>
            )}
            {packageShow.deliveryTime > 24 ? (
              <div>
                Delivery Time:{" "}
                <span data-cy="package-deliveryTime">
                  {packageShow.deliveryTime / 24}
                </span>{" "}
                days
              </div>
            ) : (
              <div>
                Delivery Time:{" "}
                <span data-cy="package-deliveryTime">
                  {packageShow.deliveryTime}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
