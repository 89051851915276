//react
import React, { useCallback, useState } from "react";

//Booking Components
import BookingContactEmail from "../components/bookingComponents/BookingContactEmail";
import BookingContactNumber from "../components/bookingComponents/BookingContactNumber";
import BookingContactPerson from "../components/bookingComponents/BookingContactPerson";
import BookingEventAddress from "../components/bookingComponents/BookingEventAddress";
import BookingEventDate from "../components/bookingComponents/BookingEventDate";
import BookingEventDetails from "../components/bookingComponents/BookingEventDetails";
import BookingEventTime from "../components/bookingComponents/BookingEventTime";
import BookingProfession from "../components/bookingComponents/BookingProfession";
// import BookingPExpertise from "../components/bookingComponents/BookingPExpertise";
// import BookingVExpertise from "../components/bookingComponents/BookingVExpertise";
import BookingEventDuration from "../components/bookingComponents/BookingEventDuration";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

//firebase
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";

export default function Booking() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState();
  const [profError, setProfError] = useState(false);

  const hostname = window.location.hostname;

  const addData = useCallback((id, data) => {
    if (hostname === "localhost") {
      console.log("local onChange: ", id, data);
    }

    setFormData((prevValue) => {
      return {
        ...prevValue,
        [id]: data,
      };
    });
  }, []);

  // useEffect(() => {
  //   console.log("profError: ", profError);
  // });

  const onSubmit = async (e) => {
    e.preventDefault();

    if (hostname === "localhost") {
      console.log("local Submitted: ", formData);
    }

    const docRef = collection(db, "bookings");

    try {
      await addDoc(docRef, {
        ...formData,
        submittedAt: serverTimestamp(),
        completed: false,
      });
      toast.success("Your form has been submitted, we will be in touch.");
      navigate("/");
    } catch (error) {
      console.log(error.message);
      toast.error("Something went wrong with your submission");
    }

    // if (hostname === "localhost" && formData.email === "cypress@testing.com") {
    //   console.log("local Submitted: ", formData);
    //   toast.success("Your form has been submitted, we will be in touch.");
    //   navigate("/");
    //   console.log("Cypress test submission complete");
    // } else {
    //   const docRef = collection(db, "bookings");

    //   try {
    //     await addDoc(docRef, {
    //       ...formData,
    //       submittedAt: serverTimestamp(),
    //       completed: false,
    //     });
    //     toast.success("Your form has been submitted, we will be in touch.");
    //     navigate("/");
    //   } catch (error) {
    //     console.log(error.message);
    //     toast.error("Something went wrong with your submission");
    //   }
    // }
  };

  return (
    <>
      <div
        data-cy="booking-page"
        className="flex h-full bg-gray-100 p-0 sm:p-5"
      >
        <div className="bg-white sm:my-2 sm:p-5 flex mx-auto w-full sm:w-3/5 rounded drop-shadow-lg">
          <form data-cy="random-booking-form" onSubmit={onSubmit}>
            <h1
              data-cy="random-booking-heading"
              className="my-5 ml-4 relative text-xl sm:text-2xl capitalize font-light"
            >
              book a photographer/videographer
            </h1>
            <BookingProfession addData={addData} setProfError={setProfError} />
            {profError && (
              <p
                data-cy="profession-warning"
                className="ml-4 mb-4 text-xs font-bold text-red-600 "
              >
                Please select at least one
              </p>
            )}
            {/* <BookingPExpertise addData={addData} /> */}
            {/* <BookingVExpertise addData={addData} /> */}
            <BookingEventDate addData={addData} />
            <BookingEventTime addData={addData} />
            <BookingEventDuration addData={addData} />
            <BookingEventDetails addData={addData} />
            <BookingEventAddress addData={addData} />
            <BookingContactPerson addData={addData} />
            <BookingContactEmail addData={addData} />
            <BookingContactNumber addData={addData} />

            {/* <PhotoOutput addData={addData} /> */}
            {/* <VideoOutput addData={addData} /> */}
            <div className="my-5 flex">
              <button
                data-cy="random-booking-submit"
                disabled={profError}
                className={`${
                  profError
                    ? "bg-gray-400 cursor-wait"
                    : "bg-orange-400 hover:bg-orange-500 active:bg-orange-600"
                }text-white text-2xl mx-auto shadow-lg px-4 py-2`}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
