import React, { useState } from "react";

export default function Keywords({
  clickWord,
  initialQuery,
  themeSelect,
  themeDeselect,
}) {
  const [showKeywords, setShowKeywords] = useState(true);
  const [highlightWord, setHightlightWord] = useState(initialQuery);

  const keywords = [
    "family",
    "cocktail dress",
    "outdoor",
    "formal",
    "concert",
    "couple",
    "music",
    "headshot",
    "corporate",
    "traditional",
    "pregnancy",
    "baby",
    "child",
    "birthday",
    "traditional wedding",
    "white wedding",
    "car",
    "decor",
    "preparation",
    "product",
    "matric dance",
    "fashion",
    "model",
    "bride",
    "groom",
    "food",
    "black and white",
    "flowers",
    "studio",
    "podcast",
    "graduation",
    "conference",
  ];

  return showKeywords ? (
    <>
      <div className="md:px-5 w-full flex">
        <div className="w-full lg:w-2/3 mx-auto">
          {keywords.map((keyword) => {
            const convert = (word) => {
              console.log(word);
              clickWord(word);
              setHightlightWord(word);
            };

            return (
              <p
                data-cy="keyword"
                key={keyword}
                onClick={() => convert(keyword)}
                className={
                  highlightWord === keyword
                    ? `${themeSelect} text-white border-2 cursor-pointer inline-block md:text-base text-sm px-2 py-1 md:px-4 md:py-2 m-1`
                    : `${themeDeselect} border-2 cursor-pointer inline-block md:text-base text-sm px-2 py-1 md:px-4 md:py-2 m-1`
                }
              >
                {keyword}
              </p>
            );
          })}
        </div>
      </div>
      <div className="flex">
        <div className="mx-auto">
          <p
            className={`text-center inline-block text-white ${themeSelect} my-4 py-2 px-4 cursor-pointer rounded`}
            onClick={() => setShowKeywords(false)}
          >
            Hide Suggestions
          </p>
        </div>
      </div>
    </>
  ) : (
    <div className="flex">
      <div className="mx-auto">
        <p
          className={`text-center inline-block text-white ${themeSelect} cursor-pointer my-4 py-2 px-4 rounded`}
          onClick={() => setShowKeywords(true)}
        >
          Search Suggestions
        </p>
      </div>
    </div>
  );
}
