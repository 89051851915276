import React from "react";
import { useNavigate } from "react-router-dom";

export default function CallToAction() {
  const navigate = useNavigate();

  return (
    <div className="z-30 w-full gap-3 h-1/4 mb-10">
      <div className="text-center">
        <div className="m-5">
          <button
            data-cy="hire-footer-button"
            onClick={() => navigate("/booking")}
            className="mx-auto capitalize font-bold text-center text-lg md:text-2xl py-2 px-5 bg-[#fa9204] hover:bg-orange-500 active:bg-orange-600 text-white rounded-full"
          >
            hire a professional
          </button>
        </div>
        <div>
          <button
            data-cy="view-footer-button"
            onClick={() => navigate("/portfolios")}
            className="mx-auto capitalize font-bold text-center text-lg md:text-2xl py-2 px-5 bg-[#fa9204] hover:bg-orange-500 active:bg-orange-600 text-white rounded-full"
          >
            view our professionals
          </button>
        </div>
      </div>
    </div>
  );
}
