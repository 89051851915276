import React, { useState, useEffect } from "react";
import { photoCamsArray } from "../../assets/globalArrays/photoCamsArray";

export default function PhotoCams({
  packageShow,
  packageDocReady,
  containerClass,
  iconClass,
  heading,
}) {
  const [photoCams, setPhotoCams] = useState("");
  const [displaySection, setDisplaySection] = useState(false);

  useEffect(() => {
    if (packageDocReady) {
      setPhotoCams(packageShow.photoCams);
    }
  }, [packageDocReady, packageShow]);

  const display = () => {
    setDisplaySection(!displaySection);
  };

  return (
    <>
      <div className="cursor-pointer" onClick={display}>
        <p data-cy="package-photoCameras-heading" className={heading}>
          Photo Cameras
        </p>
        {displaySection && (
          <div className="grid grid-cols-2 md:grid-cols-4 md:gap-4">
            {photoCams &&
              photoCams.map((item) => {
                const i = photoCamsArray().findIndex((e) => e.value === item);
                // const i = 0;
                // console.log("Item: ", item);
                // console.log("i: ", i);
                const icon = photoCamsArray(iconClass)[i].icon;
                const title = photoCamsArray()[i].title;
                return (
                  <div key={item} className={containerClass}>
                    <span className={iconClass}>{icon}</span>
                    <span
                      data-cy="package-photoCameras-icons"
                      className="capitalize ml-2 text-xs"
                    >
                      {title}
                    </span>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </>
  );
}
