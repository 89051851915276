import React, { useEffect, useState } from "react";
import { professionArray } from "./arrays/professionArray";

export default function BookingProfession({ addData, setProfError }) {
  //set data and id
  const [data, setData] = useState([]);
  const [dataId, setDataId] = useState("");

  useEffect(() => {
    if (data.length > 0) {
      addData("profession", data);
      setProfError(false);
    } else if (data.length === 0) {
      setProfError(true);
    }
    // console.log("dataId: ", dataId);
    // console.log("data: ", data);

    // console.log("useEffect Profession: ", dataId, data);
  }, [data, dataId, addData, setProfError]);

  const onChange = (e) => {
    const { id, value, checked } = e.target;
    // console.log("OnChange before: ", id, value, checked);
    if (!checked) {
      setData(data.filter((word) => word !== value));
      return;
    } else if (checked) {
      // console.log("Checked: ", id, value, checked);
      setData((prevValue) => {
        return [...prevValue, value];
      });
    }
    setDataId(id);
  };

  return (
    <>
      <h3 className="ml-4 mb-4 font-semibold text-gray-900">
        I am looking for...
      </h3>
      <div onChange={onChange}>
        <ul className="m-4 w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg">
          {professionArray.map((item) => (
            <li
              key={item.value}
              className="w-full border-b border-gray-200 rounded-t-lg"
            >
              <div className="flex items-center pl-3">
                <input
                  data-cy="profession-checkbox"
                  id={item.id}
                  type={item.type}
                  value={item.value}
                  className="w-4 h-4 text-orange-400 bg-gray-100 border-gray-300 rounded focus:ring-orange-400 focus:ring-2"
                />
                <label
                  className="w-full py-3 ml-2 text-sm font-medium text-gray-900 "
                  htmlFor={item.id}
                >
                  {item.title}
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
