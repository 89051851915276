import React from "react";

export default function DuringShoot({ packageShow, packageDocReady, heading }) {
  return (
    <>
      {packageDocReady && (
        <div className="my-5">
          <p data-cy="package-whileShooting" className={heading}>
            While Shooting
          </p>
          <div className="mx-2 md:mx-0 grid md:grid-cols-2">
            <div>
              Shoot Duration:{" "}
              <span data-cy="package-shootDuration">
                {packageShow.shootHours}
              </span>{" "}
              hours
            </div>
            {packageShow.teamSize > 1 ? (
              <div>
                Team Size:{" "}
                <span data-cy="package-teamSize">{packageShow.teamSize}</span>{" "}
                people
              </div>
            ) : (
              <div>
                Team Size:{" "}
                <span data-cy="package-teamSize">{packageShow.teamSize}</span>{" "}
                person
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
