import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function BookingContactNumber({ addData }) {
  const [value, setValue] = useState();

  useEffect(() => {
    if (value) {
      addData("contactNumber", value);
    }
  }, [value, addData]);

  return (
    <>
      <div className="m-4 md:flex items-center">
        <h2 className="mr-2">Contact Number:</h2>
        <PhoneInput
          // international
          // countryCallingCodeEditable={false}
          placeholder="Starting with 0"
          defaultCountry="ZA"
          value={value}
          onChange={setValue}
          className="w-4/5 sm:w-1/2"
          required
          data-cy="random-booking-phonenumber"
        />
      </div>
    </>
  );
}
