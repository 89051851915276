import React from "react";
import { auth } from "../firebase";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { toast } from "react-toastify";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import logo from "../assets/logo.png";

export default function Navigation() {
  const [userId, setUserId] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState();
  // const [user, setUser] = useState()
  let [open, setOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        // console.log(user);
        if (user) {
          setUserId(user.uid);
          setIsLoggedIn(true);
        } else {
          setUserId("");
          setIsLoggedIn(false);
        }
      },
      []
    );

    return unsubscribe;
  }, []);

  //logout of the system
  const onLogOut = () => {
    signOut(auth)
      .then(() => {
        toast.success("You are signed out");
        console.log("You are signed out");
        navigate("/login");
      })
      .catch((error) => {
        toast.error("Something went wrong");
        console.log(error.message);
      });
    setOpen(false);
  };

  //decoration for current location on nav panel
  const pathMatchRoute = (route) => {
    if (route === location.pathname) return true;
  };

  let links = [
    {
      name: "Portfolios",
      link: "/portfolios",
      show: !isLoggedIn,
      onClick: () => setOpen(false),
    },
    {
      name: "Profile",
      link: `/profile-edit/${userId}`,
      show: isLoggedIn,
      onClick: () => setOpen(false),
    },
    {
      name: "Portfolio",
      link: `/portfolio-edit/${userId}`,
      show: isLoggedIn,
      onClick: () => setOpen(false),
    },
    {
      name: "Studio",
      link: `/studio-edit/${userId}`,
      show: isLoggedIn,
      onClick: () => setOpen(false),
    },
    {
      name: "Packages",
      link: `/packages-editable/${userId}`,
      show: isLoggedIn,
      onClick: () => setOpen(false),
    },
    {
      name: "Reviews",
      link: `/proreviews/${userId}`,
      show: isLoggedIn,
      onClick: () => setOpen(false),
    },
    // {
    //   name: "Studios",
    //   link: "/studios",
    //   show: !isLoggedIn,
    //   onClick: () => setOpen(false),
    // },
    {
      name: "Packages",
      link: "/packages",
      show: !isLoggedIn,
      onClick: () => setOpen(false),
    },
    {
      name: "FAQs",
      link: "/faqs",
      show: !isLoggedIn,
      onClick: () => setOpen(false),
    },
    {
      name: "Logout",
      link: "",
      show: isLoggedIn,
      onClick: onLogOut,
    },
  ];

  return (
    <>
      <div className="h-16">
        <nav className="bg-black px-2 sm:px-4 py-2.5 fixed w-full z-20 top-0 left-0 border-b border-black">
          <div className="container flex flex-wrap items-center justify-between md:mx-auto">
            <a href="/" className="flex items-center">
              <img src={logo} className="h-6 mr-3 sm:h-9" alt="BraThula Logo" />
              <span className="self-center text-white text-sm md:text-xl font-semibold whitespace-nowrap">
                BraThula (beta)
              </span>
            </a>
            <div className="flex md:order-2">
              {!isLoggedIn && (
                <button
                  data-cy="join-nav-button"
                  type="button"
                  onClick={() => navigate("/brathulais")}
                  className="text-white bg-[#fa9204] hover:bg-orange-500 active:bg-orange-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-1 py-1 sm:px-5 sm:py-2.5  text-center md:mr-0"
                >
                  Join Our Network
                </button>
              )}
              <button
                data-collapse-toggle="navbar-sticky"
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                aria-controls="navbar-sticky"
                aria-expanded="false"
                onClick={() => setOpen(!open)}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div
              className={`items-center justify-between ${
                open ? "" : "hidden"
              } md:flex w-full md:w-auto md:order-1"
              id="navbar-sticky`}
            >
              <ul className="flex flex-col border py-2 px-1 mt-4 border-gray-100 rounded-lg bg-gray-50 md:space-x-4 md:flex-row md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
                {links.map((link) => {
                  return (
                    <React.Fragment key={link.name}>
                      {link.show && (
                        <li key={link.name}>
                          <NavLink
                            to={link.link}
                            onClick={link.onClick}
                            className={`block py-2 pl-3 pr-4 text-gray-600 bg-transparent rounded hover:text-white hover:bg-[#fa9204] md:p-1 ${
                              pathMatchRoute(link.link) &&
                              `border-2 border-x-0 border-orange-400 text-black`
                            } ${link.show === false && `hidden`}`}
                            aria-current="page"
                          >
                            {link.name}
                          </NavLink>
                        </li>
                      )}
                    </React.Fragment>
                  );
                })}
              </ul>
            </div>
            {isLoggedIn && <div>Hi </div>}
          </div>
        </nav>
      </div>
    </>
  );
}
