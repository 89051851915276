import React from "react";

export default function EnquiryComsPref({ addData }) {
  const onChange = (e) => {
    const { id, value } = e.target;

    addData(id, value);
  };
  return (
    <>
      <div className="m-4">
        <h3>Communication Preference</h3>
        <div className="flex items-center mb-4">
          <select
            required
            id="comsPreference"
            name="comsPreference"
            onChange={onChange}
            data-cy="portfolio-enquiry-commsPreference"
          >
            <option value="" defaultValue="Please Select" selected disabled>
              Please Select
            </option>
            <option value="Email">Email</option>
            <option value="Call">Phone Call</option>
            <option value="Whatsapp">Whatsapp</option>
          </select>
        </div>
      </div>
    </>
  );
}
