import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";

export default function PackagesPreview() {
  const [packages, setPackages] = useState([]);
  //   const [packagesDocsReady, setPackageDocsReady] = useState(false);
  //   const [packagesId, setPackagesId] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const colRef = collection(db, "packages");
      const q = query(
        colRef,
        where("adminPublish", "==", "true"),
        where("userPublish", "==", "true"),
        where("images", "!=", "[]")
      );
      const snapshots = await getDocs(q);
      const docs = snapshots.docs.map((doc) => doc.data());
      //   const proIDs = snapshots.docs.map((doc) => doc.data().user);

      //   const docsId = snapshots.docs.map((doc) => doc.id);
      //   setPackagesId(docsId);
      setPackages(docs);
      // setUserIDs(new Set(proIDs));
      //   setPackageDocsReady(true);
    })();
  }, []);

  //   useEffect(() => {
  //     console.log("Packages: ", packages);
  //   }, [packages]);

  //   useEffect(() => {
  //     if (packagesDocsReady) {
  //       packages.forEach((element) => {
  //         const imageKitUrl = element.images[0].replace(
  //           "https://storage.googleapis.com/",
  //           "https://ik.imagekit.io/ajuaxvsrja/tr:q-80,f-auto/"
  //         );

  //         element.push({ preview: imageKitUrl });
  //       });
  //     }
  //   }, [packages, packagesDocsReady]);

  //   return <div>PackagesPreview</div>;
  return (
    <>
      <p
        data-cy="packages-preview-heading"
        className="text-3xl text-center font-light"
      >
        Packages
      </p>
      <div className="overflow-hidden bg-orange-100 flex">
        <ul
          data-cy="packages-preview-div"
          onClick={() => navigate("/packages")}
          className="animate-infinite-scroll cursor-pointer z-5 flex h-80"
        >
          {[...packages, ...packages].map((item, index) => (
            <li
              data-cy="solo-packages-div"
              className=" mx-1 md:mx-2 my-auto relative flex flex-shrink-0"
              key={index}
            >
              <span className="absolute px-1 text-orange-600 w-full truncate left-0 top-0">
                {item.name}
              </span>
              <img
                data-cy="packages-preview-images"
                className="h-72 rounded-lg flex-shrink-0"
                src={item.images[1]}
                alt={`${item.name} preview-1`}
                loading="lazy"
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
