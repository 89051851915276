import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//import components
import ListingImage from "./listingComponents/ListingImage";
import ListingDetails from "./listingComponents/ListingDetails";
import GeoDetails from "./listingComponents/GeoDetails";
import TravelCost from "./listingComponents/TravelCost";

//import portfolio component
// import Portfolio from "../../pages/Portfolio";

export default function Listing({ profiles, userAddress }) {
  const navigate = useNavigate();
  const [routeDistance, setRouteDistance] = useState();

  const handleRouteDistance = (distance) => {
    setRouteDistance(distance);
  };

  // const portfolioShow = (professional) => {
  //   console.log("Portfolio Show: ", professional.user);
  //   navigate(`/portfolio/${professional.user}`, { state: professional });
  //   return <Portfolio profile={professional} />;
  // };

  return (
    <div className="flex">
      {profiles && (
        <div className="mx-auto mt-2 w-full md:w-10/12 lg:3/4">
          <div className="grid flex-col gap-3 m-2 md:grid-cols-2 p-1">
            {profiles.map((profile) => {
              return (
                <div
                  data-cy="portfolios-div"
                  key={profile.user}
                  className="w-full flex flex-row items-center bg-gray-300 hover:bg-gray-400 border rounded-lg shadow cursor-pointer"
                  onClick={() => navigate(`/portfolio/${profile.user}`)}
                >
                  <ListingImage profile={profile} />
                  <ListingDetails profile={profile} />
                  {/* <GeoDetails
                    profile={profile}
                    userAddress={userAddress}
                    handleRouteDistance={handleRouteDistance}
                  />
                  <TravelCost profile={profile} routeDistance={routeDistance} /> */}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
