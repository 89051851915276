import React, { useEffect, useState } from "react";

export default function PhotoDuration({ addData, profile, dataCy }) {
  const [cost, setCost] = useState();
  const rate = profile.photographer_rate * 1;

  useEffect(() => {
    addData("photo_cost", cost);
  }, [cost, addData]);

  const onChange = (e) => {
    const { id, value } = e.target;

    setCost(value * rate);

    addData(id, value);
  };
  return (
    <div className="m-4">
      <label>
        <span>Photography hours</span>
        <input
          data-cy={dataCy}
          type="number"
          id="photoDuration"
          min="0"
          step="1"
          max="10"
          onChange={onChange}
          required
          className="ml-2 border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-0 focus:border-b focus:border-orange-400 border-orange-400"
        />
      </label>
      {cost > 0 && <p className="my-5 text-xl text-orange-400">R{cost}</p>}
    </div>
  );
}
