import React, { useEffect, useState } from "react";
import VideoHeader from "./VideoHeader";
import MobileHeader from "./MobileHeader";
import { useNavigate } from "react-router-dom";

export default function HomeHeader() {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 768;

  const navigate = useNavigate();

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <>
      <header className="relative grid grid-rows-3 gap-0 items-center justify-center z-0 w-full overflow-hidden md:mb-20">
        <div className="text-center absolute right-1 top-1 z-40">
          <button
            data-cy="join-header-button"
            onClick={() => navigate("/brathulais")}
            className="capitalize md:font-bold text-center text-sm md:text-lg  py-2 px-2 md:px-5 bg-pink-500 hover:bg-pink-600 active:bg-pink-700 text-white rounded-sm shadow"
          >
            join our network
          </button>
        </div>
        <div className="z-30">
          <h1
            data-cy="main-heading"
            className="px-2 m-4 text-4xl text-white font-bold w-3/4 md:text-6xl"
          >
            Find Independent Photographers and Videographers In One Place
            {/* All Your Photography and Videography needs in one place */}
          </h1>
        </div>
        <div className="z-30 m-1 h-1/4">
          <p
            data-cy="sub-heading"
            className="mx-4 font-light text-white text-2xl md:text-4xl"
          >
            Tap Into The <i>BraThula</i> Network
          </p>
        </div>
        <div className="z-30 w-full gap-3 h-1/4 mb-10">
          <div className="text-center">
            <div className="m-5">
              <button
                data-cy="hire-header-button"
                onClick={() => navigate("/booking")}
                className="mx-auto capitalize font-bold text-center text-lg md:text-2xl py-2 px-5 bg-[#fa9204] hover:bg-orange-500 active:bg-orange-600 text-white rounded-full"
              >
                hire a professional
              </button>
            </div>
            <div className="">
              <button
                data-cy="view-header-button"
                onClick={() => navigate("/portfolios")}
                className="xl:mb-10 mx-auto capitalize font-bold text-center text-lg md:text-2xl py-2 px-5 bg-[#fa9204] hover:bg-orange-500 active:bg-orange-600 text-white rounded-full"
              >
                view our professionals
              </button>
            </div>
          </div>
        </div>
        {width > breakpoint ? <VideoHeader /> : <MobileHeader />}
      </header>
    </>
  );
}
