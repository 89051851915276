import React from "react";

export default function Name({ packageShow }) {
  //   useEffect(() => {
  //     console.log("Package Name: ", packageShow.name);
  //   }, [packageShow]);

  return (
    <div className="m-5">
      <p
        data-cy="package-name-heading"
        className="text-center text-2xl md:text-4xl text-bold"
      >
        {packageShow?.name}
      </p>
    </div>
  );
}
