import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export default function EventAddress({ addData, dataCy }) {
  const [address, setAddress] = useState("");
  const [region, setRegion] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [selectPrompt, setSelectPrompt] = useState(false);

  useEffect(() => {
    const work = addData("region", region);

    return work;
  }, [region, addData]);

  useEffect(() => {
    const work = addData("address", address);

    return work;
  }, [address, addData]);

  useEffect(() => {
    const work = addData("coordinates", coordinates);

    return work;
  }, [coordinates, addData]);

  useEffect(() => {
    if (selectPrompt === true) {
      setRegion("");
    }
  }, [selectPrompt]);

  const onChange = (value) => {
    setAddress(value);
    setSelectPrompt(true);
    console.log("Value: ", value);
  };

  const handleSelect = async (value) => {
    console.log("Value: ", value);
    setSelectPrompt(false);
    const result = await geocodeByAddress(value);
    const components = result[0].address_components;
    // console.log("Result: ", result);

    if (components.length === 6) {
      setRegion(components[2].long_name + ", " + components[4].short_name);
    } else if (components.length === 7) {
      setRegion(components[2].long_name + ", " + components[4].short_name);
      console.log(components[2]);
    } else if (components.length === 8) {
      setRegion(components[3].long_name + ", " + components[5].short_name);
    }

    const coordinates = await getLatLng(result[0]);
    setCoordinates(coordinates);

    setAddress(result[0].formatted_address);
  };

  return (
    <>
      <div className="m-4">
        <PlacesAutocomplete
          value={address}
          onChange={onChange}
          onSelect={handleSelect}
          highlightFirstSuggestion={true}
          searchOptions={{
            componentRestrictions: { country: ["za"] },
            // types: ["address"],
            // types: ["establishment"],
            // fields: ["name", "place_id", "formatted_address"],
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <label>
                <p className="mr-2">Event Address</p>
                <p className="text-sm w-4/5 sm:w-3/4 text-gray-500">
                  (Only the professional you choose will get access to this
                  address)
                </p>
                {selectPrompt && (
                  <p className="text-sm text-red-500">
                    Please select your address from the drop down suggestions
                  </p>
                )}

                <input
                  {...getInputProps({
                    placeholder: "Enter Address",
                    className: "location-search-input",
                  })}
                  required
                  className="block w-4/5 md:w-1/2 border-b-2 border-t-0 border-l-0 border-r-0 focus:ring-0 focus:border-b focus:border-orange-400 border-orange-400"
                  data-cy={dataCy.address}
                />
              </label>

              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {
                        backgroundColor: "orange",
                        cursor: "pointer",
                        borderRadius: "25px",
                        paddingLeft: "10px",
                      }
                    : {
                        backgroundColor: "white",
                        cursor: "pointer",
                        color: "dimGray",
                        paddingLeft: "10px",
                      };
                  return (
                    <div
                      key={suggestion.description}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span data-cy={dataCy.suggestion}>
                        {suggestion.description}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    </>
  );
}
