import React, { useState, useRef } from "react";
import { useInstantSearch, useSearchBox } from "react-instantsearch";
import Keywords from "./Keywords";

export default function CustomSearchBox(props) {
  const { query, refine } = useSearchBox(props);
  const { status } = useInstantSearch();
  const [inputValue, setInputValue] = useState(query);
  const inputRef = useRef(null);
  const themeVariants = {
    pinkSelect: "bg-pink-500 border-pink-500",
    pinkDeselect: "hover:bg-pink-600 active:bg-pink-700 border-pink-500",
    redSelect: "bg-red-500 border-red-500",
    redDeselect:
      "border-red-500 hover:bg-red-600 hover:text-white active:bg-red-700",
    orangeSelect: "bg-orange-400 border-orange-400",
    orangeDeselect:
      "border-orange-400 hover:bg-orange-600 hover:text-white active:bg-orange-700",
  };
  const themeSelect = themeVariants["orangeSelect"];
  const themeDeselect = themeVariants["orangeDeselect"];
  // const typicalColor = "[#fa-9204]";

  const isSearchStalled = status === "stalled";

  function setQuery(newQuery) {
    setInputValue(newQuery);

    refine(newQuery);
  }

  const clickWord = (word) => {
    setQuery(word);
  };

  return (
    <>
      <div className="w-11/12 md:w-1/2 items-center mx-auto mb-5 pt-3">
        <form
          action=""
          role="search"
          noValidate
          className="relative h-1/2"
          onSubmit={(event) => {
            event.preventDefault();
            event.stopPropagation();

            if (inputRef.current) {
              inputRef.current.blur();
            }
          }}
          onReset={(event) => {
            event.preventDefault();
            event.stopPropagation();

            setQuery("");

            if (inputRef.current) {
              inputRef.current.focus();
            }
          }}
        >
          <input
            data-cy="algolia-search-input"
            className="caret-orange-500 block w-full md:pl-9 md:pr-3 py-2 border-orange-400 ring-orange-400 placeholder-slate-400 focus:border-1 focus:outline-none focus:border-orange-400 focus:ring-orange-400 rounded-md focus:ring-1"
            ref={inputRef}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            placeholder="type 'white wedding', 'matric dance' or 'headshot' etc"
            spellCheck={false}
            maxLength={512}
            type="search"
            value={inputValue}
            onChange={(event) => {
              setQuery(event.currentTarget.value);
            }}
          />
          {/* <button type="submit">Submit</button> */}
          {/* <button
            type="reset"
            hidden={inputValue.length === 0 || isSearchStalled}
          >
            Reset
          </button> */}
          <span hidden={!isSearchStalled}>Searching…</span>
        </form>
      </div>
      <Keywords
        themeSelect={themeSelect}
        themeDeselect={themeDeselect}
        clickWord={clickWord}
        initialQuery={props.initialQuery}
      />
    </>
  );
}
