import React from "react";

export default function PreShoot({ packageDocReady, packageShow, heading }) {
  return (
    <>
      {packageDocReady && (
        <div className="my-5">
          <p data-cy="package-preshoot" className={heading}>
            Pre-Shoot
          </p>
          <div className="mx-2 md:mx-0 grid md:grid-cols-2">
            <div>
              {packageShow.advanceNotice > 24 ? (
                <div>
                  Advance Notice:{" "}
                  <span data-cy="package-advanceNotice">
                    {packageShow.advanceNotice / 24}
                  </span>{" "}
                  days
                </div>
              ) : (
                <div>
                  Advance Notice:{" "}
                  <span data-cy="package-advanceNotice">
                    {packageShow.advanceNotice}
                  </span>{" "}
                  hours
                </div>
              )}
            </div>
            <div>
              {packageShow.cancellationPolicy ? (
                <div>
                  Cancellation Policy:{" "}
                  <span data-cy="package-cancellationPolicy">
                    {packageShow.cancellationPolicy / 24}
                  </span>{" "}
                  days{" "}
                </div>
              ) : (
                <div>
                  Cancellation Policy:{" "}
                  <span data-cy="package-cancellationPolicy">
                    {packageShow.cancellationPolicy}
                  </span>{" "}
                  hours{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
