import React from "react";
import { useNavigate } from "react-router-dom";
// import avatar from "../../assets/Avatar/photo.svg";
import { IoMdStar } from "react-icons/io";
// import { ImLocation } from "react-icons/im";

export default function Details({ packageSolo, packagesDocsReady, packageId }) {
  const navigate = useNavigate();

  const review = (
    packageSolo.profileReviewsPublishedRating /
    packageSolo.profileReviewsPublishedNo
  ).toFixed(1);

  return (
    <>
      {packagesDocsReady && (
        <div
          className="h-1/5 pl-2 cursor-pointer"
          onClick={() => navigate(`/package/${packageId}`)}
        >
          <div className="">
            <p data-cy="packages-packageNames" className="font-bold">
              {packageSolo?.name}
            </p>
            {packageSolo.type === "onSite" && (
              <p data-cy="packages-site" className="text-gray-600 capitalize">
                on-site photoshoot
              </p>
            )}
            {packageSolo.type === "studio" && (
              <p data-cy="packages-sites" className="text-gray-600 capitalize">
                studio photoshoot
              </p>
            )}
            {/* <div className="flex">
              <ImLocation />
              <span>{packageSolo.profileRegion}</span>
            </div> */}
            <div className="flex">
              <p data-cy="packages-proNames">{packageSolo.profileName}</p>
              {packageSolo.profileReviewsPublishedNo && (
                <span className="align-top ml-1">
                  <IoMdStar
                    data-cy="packages-reviews"
                    title="reviews"
                    className="align-top text-orange-600 font-bold inline-block text-xl"
                  />{" "}
                  {review}
                </span>
              )}
            </div>
          </div>
          <div className="relative">
            <div className="text-center"></div>
          </div>
          <div>
            <p
              data-cy="packages-prices"
              className="absolute shadow hover:shadow-md rounded-sm bottom-1 right-1 text-right text-xl p-3 bg-orange-400"
            >
              R{packageSolo?.price}
            </p>
          </div>
        </div>
      )}
    </>
  );
}
