import React from "react";
import { BsHourglassSplit, BsCameraFill, BsFillGeoFill } from "react-icons/bs";

export default function Features() {
  const styling = "text-orange-400 text-6xl mx-auto";

  const elements = (specs) => [
    {
      heading: "wide range",
      paragraph:
        "Photography and Videography professionals with portfolios for all types of work and budgets",
      icon: <BsCameraFill className={specs} />,
    },
    {
      heading: "Convenience",
      paragraph: "View multiple portfolios, packages and prices and choose",
      icon: <BsHourglassSplit className={specs} />,
    },
    {
      heading: "Across the Country",
      paragraph:
        // "Anywhere in Gauteng and even outside the province we can find a professional within your vacinity",
        "Professionals provide services throughout Gauteng and growing across the country",
      icon: <BsFillGeoFill className={specs} />,
    },
  ];

  return (
    <div className="grid grid-rows-3 md:grid-rows-1 md:grid-cols-3 gap-4 h-fit my-5 md:my-20">
      {elements(styling).map((item) => {
        return (
          <div data-cy="feature-div" key={item.heading} className="w-full py-1">
            {item.icon}
            <p className="font-light text-center capitalize text-3xl my-2">
              {item.heading}
            </p>
            <p
              data-cy="feature-text"
              className="font-light text-lg text-center p-1 text-[#8f8f8f]"
            >
              {item.paragraph}
            </p>
          </div>
        );
      })}
    </div>
  );
}
